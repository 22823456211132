import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lineups from "./Lineups";
import Stats from "./Stats";
import axios from "axios";


function SlideOutFixture(){

    const navigate = useNavigate();
    const location = useLocation();
    const [fixture, setFixture] = useState();
    const [date, setDate] = useState();

    async function getFixture(){
        const response = await axios.get("/api/fixture/" + location.pathname.slice(10,100));
        setFixture(response.data);
        const temp = new  Date(response.data.date).toString().slice(3,10);
        setDate(temp);
    }

    function redirectToLeague(event){
        navigate("/leagues/" + event.target.id, {state : {data: event.target.id}});
    }
    
    function convertUTCtoLocalTime(utcTime) {
        const [hours, minutes] = utcTime.split(':');
    
        const date = new Date();
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);

        const localHours = date.getHours().toString().padStart(2, '0');
        const localMinutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${localHours}:${localMinutes}`;
      }
    
    useEffect(() => {
        let route = location.pathname;
        if(route){
            getFixture();
        }
    }, [location.pathname]);

    return (
        <div className="match-wrapper">
        
            <div className="block-1" id={fixture?.league?.id} onClick={redirectToLeague}>
                
                <div id={fixture?.league?.id} onClick={redirectToLeague}><img className="fixture-league-logo" alt="league-logo" src={fixture?.league?.logo}/></div>

                <span id={fixture?.league?.id} onClick={redirectToLeague} >
                    <div id={fixture?.league?.id} onClick={redirectToLeague} >{fixture?.league?.name}</div>
                    <div id={fixture?.league?.id} onClick={redirectToLeague} className="text-minor">{fixture?.league?.country}</div> 
                </span>
                

            </div>

            <div className="block-2">
            
                <div className="fixture-team">


                    <div><img alt="home-team-logo" src={fixture?.teams?.home?.logo}/></div>
                    <div className="name">{fixture?.teams?.home?.name}</div>
                    <div className="invisible">{fixture?.teams?.away?.name}</div>

                </div>

                

                    {fixture?.status?.status === "SC"? <div className="fixture-score">
                    
                    <div className="text-major">{convertUTCtoLocalTime(fixture?.utcTime)}</div>
                    <div className="text-minor">{date}</div>

                    </div>

                    :<div className="fixture-score">

                        <div className="text-major">{fixture?.goals?.home}-{fixture?.goals?.away}</div>
                        {fixture?.status?.status === "LIVE"? <div style={{color: "red"}} className="text-minor">{fixture.status.elapsed}'</div>: null}
                        {fixture?.status?.status === "FT"? <div className="text-minor">FullTime</div>: null}

                    </div>}
                    
                    
                    

                
                <div className="fixture-team">

                    <div><img alt="away-team-logo" src={fixture?.teams?.away?.logo}/></div>
                    <div className="name">{fixture?.teams?.away?.name}</div>
                    <div className="invisible">{fixture?.teams?.home?.name}</div>

                </div>

            </div>

            <div className="block-3">

                <div className="text-minor">MATCH INFO</div>
                
                <div className="block-1">

                <div className="fixture-stadium-logo">🏟</div>
                    
                    <span>
                        <div>{fixture?.venue?.name}</div>
                        <div className="text-minor">{fixture?.venue?.city}</div>
                    </span>

                </div>
            
                <div><span className="fixture-calendar-logo">𝄜</span><span className="ml10">{fixture?.date}</span></div>
            </div>
            

            
            {fixture?.status?.status !== "SC"?<Lineups lineups={fixture?.lineups}/>: null}
            

            {fixture?.status?.status !== "SC"?<Stats events={fixture?.events}/>: null}
            

        </div>
    )
}

export default SlideOutFixture;