import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Slider() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to toggle overlay

  function handleClick(event) {
    navigate("/leagues/" + event.target.id);
    toggleOverlay()
  }

  // Toggle overlay
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar">
      {/* Bottom navbar toggle button (visible only on small screens) */}
      <div className="navbar-toggle" onClick={toggleOverlay}>
      ☰
      </div>

      {/* Full-screen overlay containing slider items */}
      <div className={`overlay ${isOpen ? "active" : "slide-down"}`}>
        <div className="overlay-content">
          <span className="close-btn" onClick={toggleOverlay}>
            &times;
          </span>
          <h3 className="mj">Major Leagues</h3>

          {/* Slider items */}
          <div className="slider-wrapper">
            {[
              { id: 39, name: "Premier League", country: "England", logo: "39.png" },
              { id: 78, name: "Bundesliga", country: "Germany", logo: "78.png" },
              { id: 61, name: "Ligue 1", country: "France", logo: "61.png" },
              { id: 135, name: "Serie A", country: "Italy", logo: "135.png" },
              { id: 140, name: "La Liga", country: "Spain", logo: "140.png" },
              { id: 88, name: "Eredivisie", country: "Netherlands", logo: "88.png" },
              { id: 71, name: "Serie A", country: "Brazil", logo: "71.png" },
              { id: 94, name: "Primiera Liga", country: "Portugal", logo: "94.png" },
              { id: 128, name: "Liga Profesional", country: "Argentina", logo: "128.png" },
              { id: 197, name: "Super League", country: "Greece", logo: "197.png" },
              { id: 235, name: "Premier League", country: "Russia", logo: "235.png" },
              { id: 318, name: "Division 1", country: "Cyprus", logo: "318.png" },
              { id: 2, name: "UEFA Champions League", country: "World", logo: "2.png" },
              { id: 3, name: "Europa League", country: "Europe", logo: "3.png" },
              { id: 848, name: "Conference League", country: "Europe", logo: "848.png" },
              { id: 667, name: "Club Friendlies", country: "World", logo: "667.png" },
            ].map((league) => (
              <div key={league.id} onClick={handleClick} className="slider-item" id={league.id}>
                <div className="slider-1">
                  <img
                    className="slider-image"
                    onClick={handleClick} 
                    id={league.id}
                    src={`https://media.api-sports.io/football/leagues/${league.logo}`}
                    alt={league.name}
                  />
                </div>

                <div id={league.id} className="slider-2">
                  <span id={league.id}> {league.name} </span>
                  <span id={league.id} className="text-minor"> {league.country} </span>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;