import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import Logo from "./images/netscore-logo-6.webp";

function Header(){
    
    const navigate = useNavigate();
    
    function handleClick(event){
        navigate(event.target.id);
    }


    return(
       <div>

        <div className="header">
            
            <div className="netscore-logo" rel="preload" onClick={handleClick} id="/"><img fetchPriority="high" src={Logo}/></div>

        </div>

        <hr className="line"/>
       </div>
    )
}

export default Header;