import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useLocation} from "react-router-dom";


function Leagues(){

    let lastLeagueId;
    let today = new Date();
    let yesterday = new Date();
    let tomorrow = new Date();
    let methavrio = new Date();
    let proxtes = new Date();
    proxtes.setDate(today.getDate() -2);
    yesterday.setDate(today.getDate() -1 );
    tomorrow.setDate(today.getDate() + 1);
    methavrio.setDate(today.getDate() + 2);
    
    const location = useLocation();
    const navigate = useNavigate();
    const [fixtures, setFixtures] = useState();
    
    
    async function calendarFunc(event){
        const response = await axios.get("/api/fixtures/" + location.pathname.slice(9,12) + "/" + event.target.value);
        setFixtures(response.data);
    }

    async function getFixtures(date){
        
        const response = await axios.get("/api/leagues/" + location.pathname.slice(9,12) + "/" + date)
        setFixtures([...response.data.sort((a, b) => {
            if (a.status.statusSort < b.status.statusSort) return -1;
            if (a.status.statusSort > b.status.statusSort) return 1;
            return 0;
        })]);
    }

    function convertUTCtoLocalTime(utcTime) {
        const [hours, minutes] = utcTime.split(':');
    
        const date = new Date();
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);

        const localHours = date.getHours().toString().padStart(2, '0');
        const localMinutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${localHours}:${localMinutes}`;
      }

    function redirectToMatch(item){
        navigate("/fixtures/" + item.fixtureId ,{state : {data: item}});

    }

    function redirectToLeague(id){
        navigate("/leagues/" + id);
    }

    useEffect(() => {
        let route = location.pathname;
        if(route){
            getFixtures(today.toISOString().slice(0,10));
        }
    }, [location.pathname]);

    return (
        <div>
            <div className="fixtures-wrapper">

            <div className="calendar">

                
                <div  className="calendar-btn" onClick={() => getFixtures(proxtes.toISOString().slice(0,10))}>
                <span className="calendar-btn-major">{proxtes.toString().slice(0,3)}</span>
                <span className="calendar-btn-minor">{proxtes.toString().slice(4, 10)}</span>
                </div>

                <div  className="calendar-btn" onClick={() => getFixtures(yesterday.toISOString().slice(0,10))}>
                <span className="calendar-btn-major">{yesterday.toString().slice(0,3)}</span>
                <span className="calendar-btn-minor">{yesterday.toString().slice(4, 10)}</span>
                </div>

                <div className="calendar-btn" onClick={() => getFixtures(today.toISOString().slice(0,10))}>
                <span className="calendar-btn-major">Today</span>
                <span className="calendar-btn-minor">{today.toString().slice(4,10)}</span>
                </div>

                <div  className="calendar-btn" onClick={() => getFixtures(tomorrow.toISOString().slice(0,10))}>
                <span className="calendar-btn-major">{tomorrow.toString().slice(0,3)}</span>
                <span className="calendar-btn-minor">{tomorrow.toString().slice(4,10)}</span>
                </div>

                <div  className="calendar-btn" onClick={() => getFixtures(methavrio.toISOString().slice(0,10))}>
                <span className="calendar-btn-major">{methavrio.toString().slice(0,3)}</span>
                <span className="calendar-btn-minor">{methavrio.toString().slice(4,10)}</span>
                </div>

                <div><input className="calendar-datepicker" type="date" onChange={calendarFunc}/><span className="calendar-icon">𝄜</span></div>
            </div>

            <div className="fixtures">
            
            
            {fixtures?.map(item => {

                if(item.league.id !== lastLeagueId){
                    lastLeagueId = item.league.id;
                    return(<div>
                        <div className="fixtures-block-1" onClick={() => redirectToLeague(item.league.id)}>
                
                        <div><img className="fixture-league-logo" alt="league-logo" src={item?.league?.logo}/></div>

                        <span>
                            <div>{item?.league?.name}</div>
                            <div className="text-minor">{item?.league?.country}</div> 
                        </span>
                
                        
                        </div>
                        
                        <div className="fixture" onClick={() => redirectToMatch(item)}>

                    

                        {item.status.status === "FT"? <div className="status"><div className="FT">FT</div></div>: null}
                        {item.status.status === "SC"? 

                        <div className="status"><div className="datetime">
                        <span>{convertUTCtoLocalTime(item.utcTime)}</span>
                        </div></div>:null}

                        {item.status.status === "LIVE"? <div className="status">

                        {item.status.short ==="1H"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}
                        {item.status.short ==="2H"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}     
                        {item.status.short ==="HT"?<div className="live"><div className="live-banner"/><div>HT</div></div>:null}
                        {item.status.short ==="ET"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}
                        {item.status.short ==="BT"?<div className="live"><div className="live-banner"/><div>BT</div></div>:null}
                        {item.status.short ==="P"?<div className="live"><div className="live-banner"/><div>PEN</div></div>:null}
                        {item.status.short ==="SUSP"?<div className="live"><div className="live-banner"/><div>SUSP</div></div>:null}
                        {item.status.short ==="INT"?<div className="live"><div className="live-banner"/><div>INT</div></div>:null}
                        {item.status.short ==="LIVE"?<div className="live"><div className="live-banner"/><div>LIVE</div></div>:null}

                        </div>: null}

                            <div className="h2h">

                                <div className="teams">

                                    <span><img src={item.teams.home.logo}/></span>
                                    <span> {item.teams.home.name}</span>

                                </div>

                                <div className="teams">

                                    <span><img src={item.teams.away.logo}/></span>
                                    <span> {item.teams.away.name}</span>
                                    

                                </div>

                            </div>

                            {item.status.status !== "SC"? <div className="scores">

                            <div>
                                <div className="score">{item.goals.home}</div>
                            </div>

                            <div>
                                <div className="score">{item.goals.away}</div>
                            </div>

                            </div>: null}

                        </div>

                        </div>);
                }
                else {
                return(
                    <div className="fixture" onClick={() => redirectToMatch(item)}>

                    

                    {item.status.status === "FT"? <div className="status"><div className="FT">FT</div></div>: null}
                    {item.status.status === "SC"? 

                    <div className="status"><div className="datetime">
                    <span>{convertUTCtoLocalTime(item.utcTime)}</span>
                    </div></div>:null}

                    {item.status.status === "LIVE"? <div className="status">

                    {item.status.short ==="1H"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}
                    {item.status.short ==="2H"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}     
                    {item.status.short ==="HT"?<div className="live"><div className="live-banner"/><div>HT</div></div>:null}
                    {item.status.short ==="ET"?<div className="live"><div className="live-banner"/><div>{item.status.elapsed}'</div></div>:null}
                    {item.status.short ==="BT"?<div className="live"><div className="live-banner"/><div>BT</div></div>:null}
                    {item.status.short ==="P"?<div className="live"><div className="live-banner"/><div>PEN</div></div>:null}
                    {item.status.short ==="SUSP"?<div className="live"><div className="live-banner"/><div>SUSP</div></div>:null}
                    {item.status.short ==="INT"?<div className="live"><div className="live-banner"/><div>INT</div></div>:null}
                    {item.status.short ==="LIVE"?<div className="live"><div className="live-banner"/><div>LIVE</div></div>:null}

                    </div>: null}

                    <div className="h2h">

                        <div className="teams">

                            <span><img src={item.teams.home.logo}/></span>
                            <span> {item.teams.home.name}</span>

                        </div>

                        <div className="teams">

                            <span><img src={item.teams.away.logo}/></span>
                            <span> {item.teams.away.name}</span>
                            

                        </div>

                    </div>

                    {item.status.status !== "SC"? <div className="scores">

                    <div>
                        <div className="score">{item.goals.home}</div>
                    </div>

                    <div>
                        <div className="score">{item.goals.away}</div>
                    </div>

                    </div>: null}

                </div>
                );        
                }
                
            }
                

                

            )}

        </div>
            
        </div> 

        </div>
    )
}

export default Leagues;