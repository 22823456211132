import React, { useState, useEffect } from "react";

function Lineups({ lineups }) {
  console.log(lineups);
  const [homePlayers, setHomePlayers] = useState({
    h1: null,
    h2: [],
    h3: [],
    h4: [],
    h5: []
  });

  const [awayPlayers, setAwayPlayers] = useState({
    a1: null,
    a2: [],
    a3: [],
    a4: [],
    a5: []
  });

  useEffect(() => {
    if (lineups && lineups.home && lineups.away && lineups.home.startXI && lineups.away.startXI) {
      const homeTemp = { h1: null, h2: [], h3: [], h4: [], h5: [] };
      const awayTemp = { a1: null, a2: [], a3: [], a4: [], a5: [] };

      for (let i = 10; i >= 0; i--) {
        const homePlayer = lineups.home.startXI[i]?.player;
        const awayPlayer = lineups.away.startXI[i]?.player;

        if (homePlayer?.grid) {
          const [homeRow] = homePlayer.grid.split(":");
          switch (homeRow) {
            case "1":
              homeTemp.h1 = homePlayer;
              break;
            case "2":
              homeTemp.h2.push(homePlayer);
              break;
            case "3":
              homeTemp.h3.push(homePlayer);
              break;
            case "4":
              homeTemp.h4.push(homePlayer);
              break;
            case "5":
              homeTemp.h5.push(homePlayer);
              break;
            default:
              break;
          }
        }

        if (awayPlayer?.grid) {
          const [awayRow] = awayPlayer.grid.split(":");
          switch (awayRow) {
            case "1":
              awayTemp.a1 = awayPlayer;
              break;
            case "2":
              awayTemp.a2.push(awayPlayer);
              break;
            case "3":
              awayTemp.a3.push(awayPlayer);
              break;
            case "4":
              awayTemp.a4.push(awayPlayer);
              break;
            case "5":
              awayTemp.a5.push(awayPlayer);
              break;
            default:
              break;
          }
        }
      }

      setHomePlayers(homeTemp);
      setAwayPlayers(awayTemp);
    }
  }, [lineups]);

  const renderPlayer = (player) => {
    if (lineups.away.startXI === null){
      return null;
    }
      else if(player){
        let playerName = player?.name.split(" ");
        console.log(player.name);

        if(playerName.length !== 1){
          playerName = playerName[0].slice(0,1) + ". " + playerName[playerName.length - 1];
        }
        
        

        
        return(
          <div key={player?.id} className="player" grid={player?.grid}>
         <div className="player-number">{player?.number}</div> <br/> <div className="player-name">{playerName}</div>
      </div>
        )
      }
      
      
      else return null;
  };
  return (
  <div className="field-wrapper">

<div className="field">
  
  {/* Home Team */}
  <div className="lineups">
    
    <div className="penalty-area top-penalty">
      <div className="goal-area top-goal goalkeeper">
        {homePlayers.h1 && renderPlayer(homePlayers.h1)}
      </div>
    </div>

    <div className="player-wrapper top-wrapper">
      {homePlayers.h2.map(renderPlayer).length !== 0? <div>{homePlayers.h2.map(renderPlayer)}</div>: null}
      {homePlayers.h3.map(renderPlayer).length !== 0? <div>{homePlayers.h3.map(renderPlayer)}</div>: null}
      {homePlayers.h4.map(renderPlayer).length !== 0? <div>{homePlayers.h4.map(renderPlayer)}</div>: null}
      {homePlayers.h5.map(renderPlayer).length !== 0? <div>{homePlayers.h5.map(renderPlayer)}</div>: null}
    </div>
    
  </div>

  {/* Away Team */}
  <div className="lineups">
    
    <div className="player-wrapper bottom-wrapper">
      {awayPlayers.a5.map(renderPlayer).length !== 0? <div>{awayPlayers.a5.map(renderPlayer)}</div>: null}  
      {awayPlayers.a4.map(renderPlayer).length !== 0? <div>{awayPlayers.a4.map(renderPlayer)}</div>: null}  
      {awayPlayers.a3.map(renderPlayer).length !== 0? <div>{awayPlayers.a3.map(renderPlayer)}</div>: null}  
      {awayPlayers.a2.map(renderPlayer).length !== 0? <div>{awayPlayers.a2.map(renderPlayer)}</div>: null}  
    </div>

    <div className="penalty-area bottom-penalty">
      <div className="goal-area bottom-goal goalkeeper">
        {awayPlayers.a1 && renderPlayer(awayPlayers.a1)}
      </div>
    </div>
  </div>
</div>

  </div>

  );
}

export default Lineups;
